<template>
  <div class="activity-detail css-helper">
   <!-- <aside class="action">
      博物活动 &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;艺术展览
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;标题
    </aside>  -->

    <!-- 主体 -->
    <Dialog :titles="title" v-if="flag"></Dialog>
    <div class="container">
    <mainBox></mainBox>
        <!--活动信息 -->
        <div class='activity'>
            <div class='title'>
                 <span class='title-text'>活动信息</span>
                 <span>请仔细核实活动时间，确保您的正常出行</span>
            </div>
           <div class="imgBox">
                 <img :src="list.images" alt="" />
            </div>
            <div class="textBox">
                <div class='text-text'>{{list.name}}</div>
                <p>活动时间:{{list.start_time | time }}至{{ list.end_time | time}}</p>
                <p>活动地点:{{list.address}}</p>
                <p>联系电话:{{list.telphone}}</p>  
            </div>
            
        </div>
        <!-- 取票信息 -->
        <div class='ticket'>
             <div class="ticketBox">
                <div class='title'>
                    <span class='title-text'>报名信息</span>
                    <span>为了能顺利参与活动，请确保您的个人信息无误</span>
                </div>
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="姓名">
                        <el-input v-model="form.namea" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model="form.number" @blur="getCardNum" maxlength="18" placeholder="请输入证件号码"></el-input>
                    </el-form-item>
                    <el-form-item label="年龄">
                        <el-input v-model="form.age" placeholder="请输入您的年龄"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话">
                        <el-input v-model="form.call" maxlength="11" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-form>
             </div>
            
        </div>
        <div class='read'>
            <div class="redbox">
                <el-form ref="form" :model="form" label-width="80px">
                <!-- {{form.resource}} -->
                    <input type="radio" value="我已阅读并接受订票相关条款" class="radio" name="" v-model="form.resource">
                   <span class="listdiao">我已阅读并接受订票须知相关条款</span> 
             </el-form>
            </div>
        </div>
        <div class="BtnBox">
             <div class="ben" @click="baoming">立即报名</div>
        </div>
    </div>
    <!-- 尾部 -->
    <myfooter></myfooter>
  </div>
</template>

<script>

import myfooter from "../components/footer.vue";
import Dialog from "../components/Dialog";
import { fetch, post } from '../api/https';
import $ from "../components/index-page/jquery-3.3.1.min.js";
import mainBox from "@/components/main.vue";
import moment from 'moment'
export default {
  components: { myfooter, Dialog ,mainBox
  },
    filters:{
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
    }
  },
  data() {
    return {
        title:'我是标题',
        cardYear:0,
        dYear:2023,
       form:{
          namea:'',
          number:'',
          age:'',
          call:'',
          resource:'我已阅读并接受订票相关条款',
        },
        flag:false,
        list:[],
        token:sessionStorage.getItem('token') || '',
        times:3
    };
  },
  mounted(){
      
      fetch('/activity_details',{id:this.$route.query.id}).then((res) => {
          this.list = res.data
          document.title = this.list.name
      }).catch((err) => {
          
      });
  },
  methods:{
      popToast(tipTxt, time) {
      var showTime = time != null || time != undefined ? time : 2000;
      var $popToast = $(".pop-toast");
      var poptimeout = "";
      $popToast.remove();
      var popToastHtml =
        '<div class="pop-toast"><div class="toast-tip">' +
        tipTxt +
        "</div></div>";
      $popToast = $(popToastHtml);
      $(".activity-detail").append($popToast);
      if (poptimeout != "") {
        clearTimeout(poptimeout);
      }
      poptimeout = setTimeout(function () {
        $popToast.fadeOut();
      }, showTime);
    },
    getCardNum(){
        //8~12报名限制-@dou--20230720
        let year = new Date().getFullYear();
        this.dYear = year;
        var cardYear = this.form.number.slice(6,10);
        if(cardYear >= 1970 && cardYear <= year){
            var cardAge = year - cardYear
            this.form.age = cardAge
            this.cardYear = cardYear;
        }
    },
    baoming(){
        var obj = {
        token:this.token,	
        source:'pc',
        name:this.form.namea,
        phone:this.form.call,
        card_num:this.form.number,
        activity_id:this.list.id,
        age:this.form.age
        }
        var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;  
        var phone = /^1[34578]\d{9}$/;
        var namereg = /^[\u4e00-\u9fa5]{2,6}$/
        let age=/^(?:[1-9][0-9]?|1[01][0-9]|120)$/;
        if(this.form.number=='' || this.form.call=='' || this.form.name=='' || this.form.age == '') 
            {
                this.showPopupMessage('请完善信息',false)
                return  false;  
            }
            if(!namereg.test(this.form.namea)) 
            {
                this.showPopupMessage('输入的姓名有误',false)
                return  false;  
            }
            if(!reg.test(this.form.number)) 
            {
                this.showPopupMessage('输入的身份证号码有误',false)
                return  false;  
            }
            if(!age.test(this.form.age)) 
            {
                this.showPopupMessage('输入的年龄有误',false)
                return  false;  
            }
            if(!phone.test(this.form.call)) 
            {
                this.showPopupMessage('输入的手机号有误',false)
                return  false;  
            }
            if(this.form.resource == '') 
            {
                this.showPopupMessage('请勾选用户协议',false)

                return  false;  
            }
            //验证年里是6-12岁--@dou--20230720
            var minAge = this.dYear-12
            var maxAge = this.dYear-6
            console.log('活动适合年龄段为'+minAge+'~'+maxAge+'出生')
            console.log(this.cardYear);
            if(this.cardYear < minAge || this.cardYear > maxAge){
                this.showPopupMessage('活动适合年龄段为6~12岁',false)

                return  false;  
            }

            post('/activitySave',obj).then((res) => {
                    this.title = res.msg;
                    this.flag = true
                    var intovl = setInterval(() => {
                        this.times--
                        if (this.times <= 0) {
                            this.flag = false
                        clearInterval(intovl)
                        if(res.msg == '未登录,此操作需要登录')
                        {
                            this.times = 3
                            
                        }else{
                            this.$router.go(-1)
                        }
                        
                        }
                    }, 1000)
                    
                })

            
  },
}}
</script>

<style lang="scss" scoped>
.action {
  display: block;
  width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 50px;
  background:#f8f8f8;
}
.radio{
    margin:  0 10px;
}
.container{
    width:100%;
    height:100%;
    margin-top: -50px;
    background:#f8f8f8;
    padding-top: 50px;
    .activity{
    width:1400px;
    height:400px;
    margin:0 auto;
    background:white;
   .title{
        padding-top:40px;
        margin-left:40px;
       .title-text{
            font-size:24px;
            font-family:'MicrosoftYaHei';
             color:#333333;
        }
        span{
            font-size:16px;
            font-family:'MicrosoftYaHeiLight';
            color:#999999;
            padding-left:10px;
        }
    }
    .imgBox{
        width:397px;
        height:265px;
        // background:lime;
        margin-left:50px;
        margin-top:30px;
        float:left;
       img{
            width:100%;
            height:100%;
        }
    }
    .textBox{
         margin-top:30px;
        padding-left:550px;
        .text-text{
            padding-top:9px;
            color:#333333;
            font-size:28px;
            font-family:'MicrosoftYaHei';
            padding-bottom:10px;
            width: 80%;
        }
        p{
            font-size:16px;
            font-family:'MicrosoftYaHei';
            color:#999999;
            margin-top:10px;
           
        }
    }
   }
    .ticket{
       .ticketBox{
            width:1400px;
            height:340px;
            margin:0 auto;
            background:white;
            margin-top:30px;
            .title{
                padding-top:40px;
                margin-left:40px;
            .title-text{
                    font-size:24px;
                    font-family:'MicrosoftYaHei';
                    color:#333333;
                }
                span{
                    font-size:16px;
                    font-family:'MicrosoftYaHeiLight';
                    color:#999999;
                    padding-left:10px;
                }
            }
            .el-form{
                margin-top:76px;
                .el-form-item{
                    float:left;
                    margin-left:145px;
                    .el-input_inner{
                        width:300px;
                    }
                }
            }
        }
    }
}
.read{
    width:100%;
    height:auto;
    padding-bottom:60px;
    .redbox{
         width:1400px;
        margin:0 auto;
        padding-top:20px;
        
         }
}
.BtnBox{
    padding-bottom:100px;
    .ben{
        cursor: pointer;
    width:540px;
    height:60px;
    background:#c6863b;
    margin:0 auto;
    text-align:center;
    line-height:60px;
    font-size:22px;
    color:#eeeeee;
 }
}
.pop-toast {
  position: fixed;
  width: 5rem;
  padding: 0.2rem;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #ccc;
  background: rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 2px 8px #ccc;
  -moz-box-shadow: 0 2px 8px #ccc;
  box-shadow: 0 2px 8px #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  z-index: 1000000;
}

.pop-toast .toast-tip {
  font-size: 16px;
  color: #fff;
}
/deep/.el-radio__label{
    font-size: 15px;
}
/deep/.el-input__inner{
    width: 357px;
    height: 51.7px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 300;
}
/deep/.el-form-item{
    // margin-left: 88px;
    margin-left: 100px;
}
/deep/.el-form-item__label{
    margin-top: 7px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}
.listdiao{
    font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;
}
@media screen and (max-width: 1440px){
    .activity{
        width: 1266px !important;
    }
    .ticketBox{
        width: 1266px !important;
    }
    .el-form-item{
        margin-left: 95px  !important;
    }
    .redbox{
        width: 1266px !important;

    }
}
</style>
<style >
>>>.el-form-item{
    margin-left: 100px;
}
</style>