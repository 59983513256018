<template>
    <div class="alls">
        <img  src="../assets/pop/hook.png" class="loading">
        <div class="loading_title">
                {{titles}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        titles:String
    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped>
    .alls{
        width: 130px;
        height: 120px;
        background: url('../assets/pop/roundRectang.png') no-repeat;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -65px;
        margin-top: -60px;
        background-size:130px 120px ;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 30px 0;
        box-sizing: border-box;
        z-index: 100000000;
    }
    .loading{
        width: 33px;
        height: 22px;
        /* margin-top: 49px; */
    }
    .loading_title{
        font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    }
</style>
